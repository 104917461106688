.text-details {
  color: rgb(255, 255, 255);
  font-size: 16px;
  line-height: 1.5;
}

.project-image {
  height: 150px;
}

#my-header {
  width: 100%;
  overflow: hidden;
  background-color: black;
}

#welcome-message {
  margin-top: 2%;
  color: rgb(255, 255, 255);
  animation-name: welcome-message;
  animation-duration: 2s;
}

.special-text {
  color: #63c132;
}

.text-to-highlight {
  color: #63c132;
  font-size: 18px;
  font-weight: bold;
  font-family: "Kanit", sans-serif;
  line-height: 1;
}

.my-header-text {
  font-size: xx-large;
  font-weight: bolder;
  font-family: "Kanit", sans-serif;
  color: #5e239d;
}

.my-header-text:hover {
  font-size: xx-large;
  font-weight: bolder;
  font-family: "Kanit", sans-serif;
  color: #5e239d;
}

li {
  list-style-type: circle;
  margin-left: 20px;
}

body {
  background: black;
  margin: 0px;
}

.app-container {
  position: absolute;
  top: 0px;
  
  
  
}

.background-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 50%;
  height: 25%;
  transform-style: preserve-3d;
  z-index: -1;
}

.foreground-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 25%;
  transform-style: preserve-3d;
  z-index: -1;
}



.foreground-image {
  position: absolute;
  height: 100%;
  width: 100%;
  object-fit: cover;
  z-index: -1;
  transform: translateZ(-30px) scale(0.8);
  overflow: visible;
}

.background-image {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 1000%;
  object-fit: cover;
  z-index: -1;
  transform: translateZ(-60px) scale(4.1);
  overflow: visible;
}

.background-hue{
  position: absolute;
  height: 100%;
  width: 100%;
  top: 1000%;
  object-fit: cover;
  z-index: -1;
  transform: translateZ(-65px) scale(5.8);
  overflow: visible;
  
}

#background-hue-left{
  height: 100%;
  width: 12.5%;
  border-radius: 50%;
  background: radial-gradient(
    #63c132, 
    #63c13250,
    #63c13201,
    #000000
  );
  transform: translateZ(-65px) scale(4) translateX(-35%);
  
}

#background-hue-right{
  height: 100%;
  width: 20%;
  border-radius: 50%;
  background: radial-gradient(
    #63c132, 
    #63c13250,
    #63c13201,
    #000000
  );
  transform: translateZ(-65px) scale(4) translate(135%,-25%);
  
}

#robot-hand {
  left: -175%;
}

#human-hand {
  right: -175%;
}

#veins {
  top: 100%;
  right: -170%;
  animation-name: throb;
  animation-duration: 8s;
  animation-iteration-count: infinite;
}


.opening-panel {
  position: absolute;
  height: 100%;
  width: 50%;
  background-color: black;
  border: 1px solid white;
  z-index: 10;
}

#jb {
  color: #63c132;
  font-family: "Kanit", sans-serif;
  font-weight: bold;
  text-align: center;
  font-size: 45px;
  padding-left: 22px;
  padding-right: 20px;
  padding-top: 10px;
  height: 100px;
  width: 100px;
  top: 50%;
  left: -100%;
  transform: translate(-50%, -50%);
  animation-name: jb;
  animation-duration: 6s;
  opacity: 1;
}

#left-panel {
  top: 0%;
  left: -100%;
  animation-name: left-panel;
  animation-duration: 6s;
  opacity: 1;
}

#right-panel {
  top: 0%;
  right: -100%;
  animation-name: right-panel;
  animation-duration: 6s;
  opacity: 1;
}

#circuit {
  position: relative;
  height: 70%;
  width: 70%;
  top: 2100%;
  left: -200%;
  object-fit: cover;
  
}

.wrapper {
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  perspective: 10px;
}

.material-symbols-outlined {
  font-size: 50px;
  -webkit-text-fill-color: white;
}

.hour-glass {
  position: relative;
  top: -50%;
  left: 36%;
  z-index: -1;
  animation-name: hour-glass;
  animation-duration: 15s;
  animation-iteration-count: infinite;
}

.dev-ops-gear {
  z-index: -1;
  top: -54%;
  left: 49%;
  position: relative;
  animation-name: dev-ops-gear;
  animation-duration: 10s;
  animation-iteration-count: infinite;
}

.dev-ops-gear-lower {
  z-index: -1;
  top: -31%;
  left: 15%;
  position: relative;
  animation-name: dev-ops-gear-lower;
  animation-duration: 10s;
  animation-delay: 2s;
  animation-iteration-count: infinite;
  animation-direction: reverse;
}

.content-component {
  background-color: black;
  margin-top: 50px;
  margin-bottom: 250px;
  padding-top: 75px;
  padding-bottom: 25px;
  padding-left: 45px;
  padding-right: 45px;
  border: 1px solid rgb(255, 255, 255);
  margin-left: 50px;
  margin-right: 50px; 
}

#recommendations {
  margin-bottom: 15vw;
}

.my-nav-bar-toggle {
  font-size: 60px;
  font-variation-settings: "FILL" 0, "wght" 300, "GRAD" 0, "opsz" 48;
}

.my-nav-bar-toggle:hover {
  -webkit-text-fill-color: #5e239d;
}

.nav-item:hover {
  -webkit-text-fill-color: #5e239d;
}

.my-nav-bar {
  margin-left: 50px;
  margin-right: 50px;
  background-color: black;
  font-family: "Kanit", sans-serif;
  font-weight: bold;
  font-size: large;
  -webkit-text-fill-color: white;
  text-decoration-color: white;
  border: 1px solid white;
  position: sticky;
  top: 0px;
  z-index: 10px;
}

.project-image {
  height: 300px;
}

.my-sticky-footer {
  z-index: 10px;
  margin-right: 50px;
  margin-left: 50px;
  margin-top: 400px;
  bottom: 0px;
  background-color: black;
  -webkit-text-fill-color: white;
  position: sticky;
  border: 1px solid rgb(255, 255, 255);
}

@media (max-width: 767px) {
  .my-nav-bar {
    background-color: black;
    font-family: "Kanit", sans-serif;
    font-weight: bold;
    height: 80px;
    -webkit-text-fill-color: white;
    padding: 2px;
    font-size: medium;
    margin-left: 25px;
    margin-right: 25px;
  }
  .navbar-nav {
    background-color: black;
  }

  .my-nav-bar-toggle {
    font-size: 40px;
    font-variation-settings: "FILL" 0, "wght" 300, "GRAD" 0, "opsz" 48;
  }

  .navbar-toggler {
    margin-top: 2px;
    margin-bottom: 10px;
  }

  .my-sticky-footer {
    margin-left: 25px;
    margin-right: 25px;
  }

  .project-image {
    height: 180px;
  }

  .content-component {
    margin-left: 25px;
    margin-right: 25px;
    margin-top: 25px;
  }

  #welcome-message {
    text-align: end;
    padding-left: 30px;
  }
}

@media (max-width: 400px) {
  .project-image {
    height: 110px;
  }
  .special-text {
    font-size: 24px;
    margin-top: -4px;
    margin-bottom: -2px;
  }
  #welcome-message {
    text-align: end;
    padding-left: 15px;
    margin-left: -15px;
  }
  .text-details {
    font-size: 12px;
  }
  .text-to-highlight {
    font-size: 14px;
  }

  .my-sticky-footer {
    margin-left: 15px;
    margin-right: 15px;
  }

  .my-nav-bar {
    margin-left: 15px;
    margin-right: 15px;
  }

  .content-component {
    margin-left: 15px;
    margin-right: 15px;
    margin-top: 25px;
    padding-right: 20px;
  }
  .my-header-text {
    font-size: large;
  }
  #profile-pic {
    height: 150px;
  }
}

@keyframes dev-ops-gear {
  0% {
    position: relative;
    z-index: 4;
    top: -54%;
    left: 49%;
    transform: rotate(0deg);
  }
  100% {
    position: relative;
    z-index: 4;
    top: -54%;
    left: 49%;
    transform: rotate(360deg);
  }
}

@keyframes dev-ops-gear-lower {
  0% {
    position: relative;
    z-index: 4;
    top: -31%;
    left: 15%;
    transform: rotate(0deg);
  }
  100% {
    position: relative;
    z-index: 4;
    top: -31%;
    left: 15%;
    transform: rotate(360deg);
  }
}

@keyframes hour-glass {
  0% {
    position: relative;
    z-index: 0;
    top: -50%;
    left: 36%;
    transform: rotate(0deg);
  }

  100% {
    position: relative;
    z-index: 0;
    top: -50%;
    left: 36%;
    transform: rotate(360deg);
  }
}

@keyframes jb {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
    left: 50%;
    opacity: 1;
  }
  12.5% {
    transform: translate(-50%, -50%) rotate(0deg);
    left: 50%;
    opacity: 1;
  }
  25% {
    transform: translate(-50%, -50%) rotate(360deg);
    left: 50%;
    opacity: 1;
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
    left: -50%;
    opacity: 1;
  }
}

@keyframes left-panel {
  0% {
    left: 0%;
    opacity: 1;
  }
  25% {
    opacity: 1;
    left: 0%;
  }
  100% {
    opacity: 1;
    left: -100%;
  }
}

@keyframes right-panel {
  0% {
    right: 0%;
    opacity: 1;
    width: 50%;
  }
  25% {
    opacity: 1;
    right: 0%;
  }
  100% {
    opacity: 1;
    right: -100%;
  }
}

@keyframes throb {
  0% {
    top: 100%;
    right: -170%;
    opacity: 1;
    transform: translateZ(-30px) scale(0.8);
  }
  50% {
    top: 100%;
    right: -170%;
    opacity: 0.5;
    transform: translateZ(-30px) scale(0.82) translateX(0.5%);
  }
  100% {
    top: 100%;
    right: -170%;
    opacity: 1;
    transform: translateZ(-30px) scale(0.8);
  }
}
